import React from 'react';
import { Redirect } from "react-router-dom";
import fire from "../Components/Firebase";
import ReactPlayer from "react-player";
import "./Post.css";
import NavBar from "../Components/NavBar";
import NavBarHome from "../Components/NavBarHome";

function Post ({match: { params: { id} }})  {

    const [title, setTitle] = React.useState("");
    const [text, setText] = React.useState("");
    const [btext, setBText] = React.useState("");
    const [b2text, setB2Text] = React.useState("");
    const [time, setTime] = React.useState("");
    const [yt, setYt] = React.useState("");
    const [topImg, setTopImg] = React.useState("");
    const [botImg, setBotImg] = React.useState("");

    React.useEffect(() => {
        const fetch = async () => {
            const db = fire.firestore();
            var docRef = db.collection("Posts").doc(id);
            docRef.get().then((doc) => {
                if (doc.exists) {
                    setTitle(doc.data().Title);
                    setText(doc.data().Text);
                    setBText(doc.data().Body_Text);
                    setB2Text(doc.data().Body2_Text);
                    setYt(doc.data().YoutubeLink);
                    setTopImg(doc.data().T_IMG);
                    setBotImg(doc.data().B_IMG);
                    setTime(doc.data().Time.toDate().toString());
                } else {
                    window.location.href = "/404";
                }
            })
        }
        fetch();
    }, [])
    
    

    return (
        <>
            <NavBarHome/>
            <h1>{title}</h1>
            {!!yt  ?(
            <div className="wrapper-wrapper">
                <div className="player-wrapper">
                    {!!yt ? (<ReactPlayer width="100%" height="100%" className="vid" url={yt}/>) : (null)}
                </div>
            </div>
            ) : (null)
            }
            <p>{text}</p>
            {!!topImg ? (<img className = "postIMG" src={topImg}></img>) : (null)}
            
            <p>{btext}</p>
            {!!botImg ? (<img className = "postIMG" src={botImg}></img>) : (null)}
            <p>{b2text}</p>
            <footer>Posted at: {time}</footer>
            
        </>
    )
}

export default Post;