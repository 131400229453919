import firebase from 'firebase';
import "firebase/storage";

const config = {
    apiKey: "AIzaSyBsW3YLVkKsQXnSe9K-YqUuhsOB7idMfd8",
    authDomain: "devlog-7b318.firebaseapp.com",
    projectId: "devlog-7b318",
    storageBucket: "devlog-7b318.appspot.com",
    messagingSenderId: "82317936551",
    appId: "1:82317936551:web:fd428dd57c8effd57e1882"
  };
  const fire = firebase.initializeApp(config);
  
  export default fire;