import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Home from "./Pages/Home";
import Blog from "./Pages/Blog";
import DevUpdatesPage from "./Pages/DevUpdatesPage";
import Post from "./Pages/Post";
import PageNotFound from "./Pages/PageNotFound";
import MakePost from "./Pages/MakePost";
import DeletePost from "./Pages/DeletePosts"
import Login from "./Pages/LogIn";
import PrivateRoute from "./Components/PrivateRoute";
import { AuthProvider } from "./Components/Auth";

function App() {
  return (
    <Router>
        <Switch>
          <Route exact path ={"/"} render={props =>  <Home {...props} />} />
          <Route exact path ={"/blog"} render={props =>  <Blog {...props} />} />
          <Route exact path ={"/devlog"} render={props =>  <DevUpdatesPage {...props} />} />
          <Route exact path ={"/post/:id"} render={props => <Post {...props} />} />
          <Route exact path={"/Login"} render={(props) => <Login {...props} />} />
          <Route exact path ={"/404"} component={PageNotFound} />
          <AuthProvider>
            <PrivateRoute exact path ={"/makepost"} component={MakePost} />
            <PrivateRoute exact path ={"/delpost"} component={DeletePost} />
          </AuthProvider>
          
        </Switch>
    </Router>
  );
}

export default App;
