import React, { Component } from "react";
import BlogViewer from "../Components/BlogViewer";
import NavBarHome from "../Components/NavBarHome";
import "./home.css";

class Blog extends Component {

    render() {
        return (
            <>
                <NavBarHome/>
                <div className="blogbox-big">
                        <div className="blogbox-title">
                            Blast Cats Dev Log:
                        </div>
                        <BlogViewer/>
                </div>
            </>
        )
    }
}

export default Blog;