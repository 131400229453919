import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import fire from "../Components/Firebase";
import firebase from 'firebase/app'
import NavBar from '../Components/NavBar';

const useStyles = makeStyles((theme) => ({
    root: {
      '& .MuiTextField-root': {
        margin: theme.spacing(1),
        width: '60%',
        
      },
      textbox: {
        color: "white",
    }
    },
    
  }));

function MakePost() {
    const classes = useStyles();

    const [title, setTitle] = React.useState("");
    const [text, setText] = React.useState("");
    const [btext, setBText] = React.useState("");
    const [b2text, setB2Text] = React.useState("");
    const [time, setTime] = React.useState(Date().toString());
    const [yt, setYt] = React.useState("");
    const [topImg, setTopImg] = React.useState("");
    const [botImg, setBotImg] = React.useState("");
    const [tFile, setTFile] = React.useState(null);
    const [bFile, setBFile] = React.useState(null);
    const [tUrl, setTUrl] = React.useState("");
    const [bUrl, setBUrl] = React.useState("");

    var meta = {
        cacheControl: 'public,max-age=4000',
    };

    const handleSetTitle = (e) => {
        e.preventDefault();
        setTitle(e.target.value);
    }

    const handleSetYT = (e) => {
        e.preventDefault();
        setYt(e.target.value);
    }

    const handleSetText = (e) => {
        e.preventDefault();
        setText(e.target.value);
    }

    const handleSetTIMG = (e) => {
        e.preventDefault();
        setTopImg(e.target.value);
    }

    const handleSetBText = (e) => {
        e.preventDefault();
        setBText(e.target.value);
    }

    const handleSetBIMG = (e) => {
        e.preventDefault();
        setBotImg(e.target.value);
    }

    const handleSetB2Text = (e) => {
        e.preventDefault();
        setB2Text(e.target.value);
    }

    const handleChangeTFile = (e) => {
        e.preventDefault();
        if (e.target.files[0]) {
            setTFile(e.target.files[0]);
        }
    }
    const handleChangeBFile = (e) => {
        e.preventDefault();
        if (e.target.files[0]) {
            setBFile(e.target.files[0]);
        }
    }

    const handleTUpload = (e) => {
		e.preventDefault();
        const storageRef = fire.storage().ref();
        const fileRef = storageRef.child(tFile.name);
        fileRef.put(tFile, meta).then(()=> {
            fileRef.getDownloadURL().then((url) => {
                
                setTUrl(url)
                setTopImg(url)
                
            })   
        })

    };
    //If this breaks I added the meta field just now
    const handleBUpload = (e) => {
		e.preventDefault();
        const storageRef = fire.storage().ref();
        const fileRef = storageRef.child(bFile.name);
        fileRef.put(bFile, meta).then(()=> {
            fileRef.getDownloadURL().then((url) => {
             
                setBUrl(url)
                setBotImg(url)
            })   
        })

    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const db = fire.firestore();
        db.collection("Posts").add({
            Title: title,
            Text: text,
            Body_Text: btext,
            Body2_Text: b2text,
            T_IMG: topImg,
            B_IMG: botImg,
            YoutubeLink: yt,
        })
        .then((doc) => {
            console.log("Document written successfully");
            db.collection("Posts").doc(doc.id).set({
                Time: firebase.firestore.FieldValue.serverTimestamp()
            }, {merge:true})
            .then(()=>{
                window.location.href = `/post/${doc.id}`;
            })
            
            
        })
        .catch((error) => {
            console.error("Error adding document: ", error);
        })

    }


    return(
        <>
            <NavBar/>
            <form className={classes.root} noValidate autoComplete="off">
                <div className="editBox">
                    {/* Handle the Title attribute */}
                    <TextField  InputProps={{className: classes.textBox}} id="standard-basic" label="Title" onChange={handleSetTitle} />
                    {/* Handle the YoutubeLink attribute */}
                    <TextField id="standard-basic" label="Youtube Link or Soundcloud Link?" onChange={handleSetYT} />
                    {/* Handle the Text attribute */}
                    <TextField
                        id="outlined-multiline-static"
                        label="Main Text Body"
                        multiline
                        rows={4}
                        variant="outlined"
                        onChange={handleSetText}
                    />
                    {/* Handle the T_IMG attribute */}
                    <input type="file" onChange={handleChangeTFile}/>
                    <button onClick={handleTUpload}>Upload</button>
                    <TextField id="standard-basic" label="Link to First Image?" value={!!tUrl ? (tUrl) : (null)} onChange={handleSetTIMG} />
                    {/* Handle the Body_Text attribute */}
                    <TextField
                        id="outlined-multiline-static"
                        label="Second Text Body"
                        multiline
                        rows={4}
                        variant="outlined"
                        onChange={handleSetBText}
                    />
                    {/* Handle the B_IMG attribute */}
                    <input type="file" onChange={handleChangeBFile}/>
                    <button onClick={handleBUpload}>Upload</button>
                    <TextField id="standard-basic" label="Link to Second Image?" value={!!bUrl ? (bUrl) : (null)} onChange={handleSetBIMG} />
                    {/* Handle the Body2_Text attribute */}
                    <TextField
                        id="outlined-multiline-static"
                        label="Bottom Text Body"
                        multiline
                        rows={4}
                        variant="outlined"
                        onChange={handleSetB2Text}
                    />
                    <Button onClick={handleSubmit} variant="contained" >Post</Button>
                </div>
            </form>
        </>
    )
}

export default MakePost;