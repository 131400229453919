import React, { Component } from "react";
import { BrowserRouter as Link } from "react-router-dom";
import NavBarLogin from "./NavBarLogin";
import logo from "../Pages/logo.png";

class NavBar extends Component {

    render() {
        return (
            <nav className = "navbox">
                <img className = "logo" src={logo}></img>
                <Link to="/">
                            <a href="/" className="active">
                                Home
                            </a>
                </Link>

                
                {/* <NavBarLogin/> */}
            </nav>
        )
    }
}

export default NavBar;
