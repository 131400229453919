import React, { Component } from "react";
import { BrowserRouter as Link } from "react-router-dom";
import fire from "./Firebase";

class NavBarLogin extends Component {
	logout() {
		fire.auth().signOut();
	}

	checkLogIn() {
		const user = fire.auth().currentUser;
		if (user) {
			return (
				<>
                    {/* These need to be protected */}
                    <Link to="/makepost">
                                <a href="/makepost" className="active">
                                    Make A Post
                                </a>
                    </Link>
                    <Link to="/delpost">
                                <a href="/delpost" className="active">
                                    Delete A Post
                                </a>
                    </Link>
					<Link>
						<a onClick={this.logout} href="/Login" className="Register">
							Logout
						</a>
					</Link>
				</>
			);
		} else {
			return (
				<>
					<Link to="/Login">
						<a href="/Login" className="login">
							Login
						</a>
					</Link>
				</>
			);
		}
	}

	render() {
		return (
			<>
				{/*Top Login/Register navigation bar*/}
				
					{this.checkLogIn()}
				
			</>
		);
	}
}

export default NavBarLogin;