import React, { Component } from "react";
import { BrowserRouter as Link } from "react-router-dom";
import NavBarLogin from "./NavBarLogin";
import logo from "../Pages/logo.png";
import twittericon from "./twittericon.png";
import yticon from "./youtubeicon.png";

class NavBarHome extends Component {

    render() {
        return (
            <nav className = "navboxhome">
                <div className = "cornerbox">
                    <a className = "homelink" href="/">
                        <img className = "logo" src={logo}></img>
                    </a>
                    <a className = "navlink" href="https://twitter.com/BlastCatsDev">
                        <img className="twitterbutton" src={twittericon}/>
                    </a>
                    <a className = "navlink" href="https://www.youtube.com/channel/UCJiaMbBuu_y-0UWYTPzWvxQ">
                        <img className="twitterbutton" src={yticon}/>
                    </a>
                </div>
                <div className="spacer"/>
                
                {/* <NavBarLogin/> */}
            </nav>
        )
    }
}

export default NavBarHome;
