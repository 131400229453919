import React, { Component } from "react";
import NavBarHome from "../Components/NavBarHome";
import "./home.css";
import discordlogo from "./discordlogo.png"
import loglink from "./loglink.png"

class Home extends Component {

    render() {
        return (
            <>
                <NavBarHome/>
                <div className="centerbox"></div>
                <div className="homeLinks">
                    <div className="homeLinkBox">
                        Try the demo!
                        <iframe src="https://itch.io/embed/1668396?bg_color=000000&amp;fg_color=fffc20&amp;link_color=fa5c5c&amp;border_color=333333" width="100%" height="auto" frameborder="0"><a href="https://blastcatsdev.itch.io/blastcatsnightmaredemo">Blast Cats: Nightmare Demo by BLAST</a></iframe>
                    </div>
                    <div className="homeLinkBox">
                        Wishlist the game!
                        <iframe src="https://store.steampowered.com/widget/2007430/" frameborder="0" width="100%" height="100%"></iframe>
                    </div>
                    <div className="homeLinkBox">
                        Join the community!
                        <a href="https://discord.gg/WYn526c8ch"><img src={discordlogo} width= "80%"></img></a>
                    </div>
                    <div className="homeLinkBox">
                        What's new on the Dev Log?
                        <a href="/blog"><img src={loglink} width= "50%"></img></a>
                    </div>
                </div>
                
                
                
            </>
        )
    }
}

export default Home;