import React, { Component } from "react";
import fire from "../Components/Firebase";
import firebase from "firebase";
import { BrowserRouter as Redirect, Link } from "react-router-dom";
import NavBar from "../Components/NavBar";
import "./login.css";

class Login extends Component {
    constructor(props) {
        super(props);
		this.login = this.login.bind(this);
		this.state = {
			email: "",
			password: "",
			redirect: "",
		};
    }

    handleChange(e) {
		this.setState({ [e.target.name]: e.target.value });
	}

	//logs in...changes error message depending on result
	login(e) {
		e.preventDefault();
		fire
			.auth()
			.signInWithEmailAndPassword(this.state.email, this.state.password)
			.then((u) => {
				console.log("logged in");
				//if logged in go to Inventory page
				this.props.history.push("/makepost");
				console.log(this.state.redirect);
			})
			.catch((error) => {
				console.log(error);
			});
	}

    render() {
        return (
            <>
                <NavBar/>
				<div className="loginform">
                    <h1 className="loginfont">Log In:</h1>
                    <label for="email">
                        <b>Email</b>
                    </label>{" "}
                    <br></br>
                    <input
                        value={this.state.email}
                        onChange={this.handleChange.bind(this)}
                        type="text"
                        name="email"
                        class="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        placeholder="Enter email"
                    />
                    <br></br>
                    <label for="psw">
                        <b>Password</b>
                    </label>
                    <input
                        value={this.state.password}
                        onChange={this.handleChange.bind(this)}
                        type="password"
                        name="password"
                        class="form-control"
                        id="exampleInputPassword1"
                        placeholder="Password"
                    />
                    <button type="submit" onClick={this.login} class="btn btn-primary">
                        Login
                    </button>
                </div>
             </>
        )
    }
}

export default Login;