import React from "react";
import { BrowserRouter as Link } from "react-router-dom";
import fire from "../Components/Firebase";
import NavBar from "../Components/NavBar";
import Button from '@material-ui/core/Button';

function DeletePost() {
    //States for the firestore documents
    const [items, setItems] = React.useState([]);
    const [ids, setIds] = React.useState([]);

    React.useEffect(() => {
		const fetchItems = async () => {
			const db = fire.firestore();
			const data = await db.collection("Posts").orderBy("Time","desc").get();
			setItems(data.docs.map((doc) => doc.data()));
            setIds(data.docs.map((doc) => doc.id));
		};
		fetchItems();
	}, []);

    const handleDelete = (index) => {
        console.log("test")
        var id = ids[index];
        const db = fire.firestore();
        db.collection("Posts").doc(id).delete()
        .then(()=> {
            console.log("Document successfully deleted!");
            window.location.href = `/`;
        })
        .catch((error) => {
            console.error("Error removing document: ", error);
        });
    }

    return (
        <>
            <NavBar/>
            <div classsName="editBox">
                <ul >
                    {items.map((item, index) => (
                        <li key={index}>
                            <Link to={`/post/${ids[index]}`}>
                                
                                {item.Title} -------- {item.Time.toDate().toISOString().split('T')[0]}
                                
                            </Link>
                            <Button className="delbutton" variant="outlined" onClick = {function(){handleDelete(index)}}>Delete</Button>
                        </li>
                    ))}
                </ul>
            </div>
        </>
    )
}

export default DeletePost;