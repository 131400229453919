import React from "react";
import { BrowserRouter as Link } from "react-router-dom";
import fire from "../Components/Firebase";
import decimg from "../Pages/mailicon.png"
import ReactPlayer from "react-player";

function oddEven(index) {
    if (index % 2 == 0){
        return "blogli";
    }
    else{
        return "blogli-alt";
    }
}

function BlogViewer() {
    //States for the firestore documents
    const [items, setItems] = React.useState([]);
    const [ids, setIds] = React.useState([]);
    const [current, setCurrent] = React.useState(0);

    React.useEffect(() => {
		const fetchItems = async () => {
			const db = fire.firestore();
			const data = await db.collection("Posts").orderBy("Time","desc").get();
			setItems(data.docs.map((doc) => doc.data()));
            setIds(data.docs.map((doc) => doc.id));
		};
		fetchItems();
	}, []);

    return (
        <div className="blogviewbox">
            <div className="blog-scroll">
                {items.map((item, index) => (
                    <div className="blogitembox">
                            <img src={decimg} className="blogdecorator"></img>
                        <button onClick={() => setCurrent(index)} className={oddEven(index)} key={index}>
                                
                                <div className = "blogdate">{(item.Time) ? item.Time.toDate().toDateString() : "Date Unknown"}:</div>
                                <div className="bloglink" href={`/post/${ids[index]}`}>
                                {item.Title}
                                </div>
        
                        </button>
                    </div>
                ))}
            </div>
            <div className="blogpagebox">
                {(items[current]) ?
                    <>
                        <h1 className="blogTitle">{(items[current]) ? JSON.stringify(items[current].Title).replace(/[^\w\s]/gi, '') : ""}</h1>
                        {(items[current].YoutubeLink) ? 
                        <div className="VidBackground">
                            <div className="wrapper-wrapper">
                                <div className="player-wrapper">
                                    <ReactPlayer width="100%" height="100%" className="vid" url={items[current].YoutubeLink}/>
                                </div>
                            </div>
                        </div>
                        
                        : (null)}
                        
                        {(items[current].Text) ? 
                            <p className="blogP">
                                {(items[current].Text) ? JSON.stringify(items[current].Text).replace(/\\"/g, '"').substring(1, items[current].Text.length+1) : (null)}
                            </p>  
                        : (null)}
                        {!!items[current].T_IMG ? (<img className = "postIMG" src={items[current].T_IMG}></img>) : (null)}
                        {(items[current].Body_Text) ? 
                            <p className="blogP">
                                {(items[current].Body_Text) ? JSON.stringify(items[current].Body_Text).replace(/\\"/g, '"').substring(1, items[current].Body_Text.length+1) : (null)}
                            </p>  
                        : (null)}
                        {!!items[current].B_IMG ? (<img className = "postIMG" src={items[current].B_IMG}></img>) : (null)}
                        {(items[current].Body2_Text) ? 
                            <p className="blogP">
                                {(items[current].Body2_Text) ? JSON.stringify(items[current].Body2_Text).replace(/\\"/g, '"').substring(1, items[current].Body2_Text.length+1) : (null)}
                            </p>  
                        : (null)}
                         
                        <footer>Posted: {(items[current].Time) ? items[current].Time.toDate().toDateString() : "Date Unknown"}</footer>
                    </>
                : (null)
                }
                
            </div>
        </div>
    )
}

export default BlogViewer;