import React, {useEffect, useContext, useState } from "react";
import { Route, Redirect } from "react-router-dom";
import { AuthContext } from "./Auth";
import fire from "./Firebase";

//Route only if we have a logged in user.
//Which in our case is the only user, admin.
export const PrivateRoute = ({ component: RouteComponent, ...rest }) => {
    const { currentUser } = useContext(AuthContext);
    
    return (
        <Route
            {...rest}
            render={routeProps =>
                //!! means convert object to True or False, so !!currentUser means if a user exists, it is converted to true.
                !!currentUser ? (
                    <RouteComponent {...routeProps} />
                ) : (
                    <Redirect to={"/"} />
                )
            }
        />
    );
};

export default PrivateRoute;