import React, { Component } from "react";
import { BrowserRouter as Link } from "react-router-dom";
import GetPosts from "../Components/GetPosts";
import NavBarHome from "../Components/NavBarHome";
import "./home.css";
import ccpic01 from "./pose_eat01.png"
import ibpage01 from "./instructionbooklet.jpg"

class DevUpdatesPage extends Component {

    render() {
        return (
            <>
                <NavBarHome/>
                <div className="blogbox-big">
                        <h1 className="welcomebox">Dev Progress Railway</h1>
                        <GetPosts/>
                </div>
                   
                
                {/* <img src={ccpic01} alt="C.C. eating"></img> */}
                
                
            </>
        )
    }
}

export default DevUpdatesPage;