import React from "react";
import { BrowserRouter as Link } from "react-router-dom";
import fire from "../Components/Firebase";
import decimg from "../Pages/mailicon.png"

function oddEven(index) {
    if (index % 2 == 0){
        return "blogli";
    }
    else{
        return "blogli-alt";
    }
}

function GetPosts() {
    //States for the firestore documents
    const [items, setItems] = React.useState([]);
    const [ids, setIds] = React.useState([]);

    React.useEffect(() => {
		const fetchItems = async () => {
			const db = fire.firestore();
			const data = await db.collection("Posts").orderBy("Time","desc").get();
			setItems(data.docs.map((doc) => doc.data()));
            setIds(data.docs.map((doc) => doc.id));
		};
		fetchItems();
	}, []);

    return (
        <div>
            {items.map((item, index) => (
                <Link to={`/post/${ids[index]}`}>
                <div className="blogitembox">
                        <img src={decimg} className="blogdecorator"></img>
                    <div className={oddEven(index)} key={index}>
                        
                            <div className = "blogdate">{(item.Time) ? item.Time.toDate().toDateString() : "Date Unknown"}:</div>
                            <a className="bloglink" href={`/post/${ids[index]}`}>
                            {item.Title}
                            </a>
    
                    </div>
                </div>
                </Link>
            ))}
        </div>
    )
}

export default GetPosts;